import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { styleConfig } from '../../config/config';

import PipeSecondary from './PipeSecondary';
import Section from './Common/Section';
import Usps from './Usps';
import Title from './Common/Title';

const ContentModuleIntroFeatures: React.SFC<ContentModuleIntroFeaturesProps> = ({
  hasPipe,
  headline,
  content,
  title,
  usplist,
  hasCta,
}) => {
  const useStyles = makeStyles((theme: Theme) => ({
    left: {
      backgroundColor: styleConfig.color.bg.darkgrey,
      height: '100%',

      '& p': {
        '&:last-of-type': {
          marginBottom: 0,
        },
      },
    },
    leftInner: {
      padding: '100px 0',
    },
    right: {
      backgroundColor: styleConfig.color.bg.darkergrey,
    },
    rightInner: {
      height: '100%',
      padding: '100px 0',
    },
    introHeadline: {
      color: styleConfig.color.text.white,
      marginBottom: 30,

      [theme.breakpoints.up('md')]: {
        fontSize: '2.4rem!important',
      },
    },
    pipeContainer: {
      position: 'relative',
    },
  }));

  const classes = useStyles();

  return (
    <Section nopad bg={styleConfig.color.bg.darkgrey}>
      <Container maxWidth="xl">
        <Grid container className={classes.pipeContainer}>
          <Grid item>{hasPipe && <PipeSecondary />}</Grid>
        </Grid>
      </Container>

      <Grid container>
        <Grid item xl={8} lg={8} md={12} className={classes.left}>
          <Grid container justifyContent="center" className={classes.leftInner}>
            {hasPipe ? (
              <Grid item xs={10} md={10}>
                {title && (
                  <Title
                    titleColor={styleConfig.color.text.gold}
                    marginBottom={30}
                    component="h2"
                    titleHeavy
                    titleSize="h3"
                    titleText={title}
                  />
                )}

                <Typography
                  className={classes.introHeadline}
                  component="div"
                  dangerouslySetInnerHTML={{ __html: headline }}
                  variant="body2"
                />
                <Typography
                  component="div"
                  dangerouslySetInnerHTML={{ __html: content }}
                  key="cm-intro-features-content"
                  variant="body1"
                />
              </Grid>
            ) : (
              <Grid item xs={10} md={10}>
                {title && (
                  <Title
                    component="h2"
                    subtitleType="subtitle1"
                    titleHeavy
                    titleSize="h2"
                    titleText={title}
                  />
                )}

                <Typography
                  className={classes.introHeadline}
                  component="div"
                  dangerouslySetInnerHTML={{ __html: headline }}
                  variant="body2"
                />
                <Typography dangerouslySetInnerHTML={{ __html: content }} variant="body1" />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xl={4} lg={4} md={12} sm={12} className={classes.right}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.rightInner}
          >
            {hasCta ? <Usps list={usplist} /> : <Usps list={usplist} />}
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
};

interface ContentModuleIntroFeaturesProps {
  hasPipe?: boolean;
  headline: string;
  content: string;
  title?: string;
  usplist: {
    icon: string;
    label: string;
  }[];
  hasCta?: boolean;
  buttonText?: string;
  buttonLink?: string;
}

export default ContentModuleIntroFeatures;
