import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { styleConfig } from '../../config/config';

const PipeSecondary: React.FunctionComponent = (props) => {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: 'block',
      position: 'absolute',
      left: 0,
      zIndex: 10,
      transition: 'all .8s cubic-bezier(.22,.89,.61,.58)',
      borderLeft: '1px solid' + styleConfig.color.bg.gold,
      borderBottom: '1px solid' + styleConfig.color.bg.gold,
      height: '20rem',
      width: '1px',

      [theme.breakpoints.down('md')]: {
        height: '10rem',
        top: '-5rem',
        display: 'none',
      },

      [theme.breakpoints.up('md')]: {
        top: '-15px',
        height: '9rem',
      },

      '&:before': {
        content: `''`,
        position: 'absolute',
        right: '-5px',
        bottom: '-6px',
        borderRadius: '100%',

        width: '11px',
        height: '11px',
        backgroundColor: styleConfig.color.bg.gold,

        transition: 'all .3s ease-in-out',
        transitionDelay: '.5s',
        transform: 'scale(1)',
      },
    },
  }));

  const classes = useStyles(props);

  return <div className={classes.root}></div>;
};

export default PipeSecondary;
