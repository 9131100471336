import React from 'react';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';

// Config
import { styleConfig } from '../../config/config';

const Usps: React.SFC<UspsProps> = (props) => {
  const { list } = props;

  const data = useStaticQuery(graphql`
    {
      iconHandout: file(relativePath: { eq: "icons/handout.svg" }) {
        publicURL
      }

      iconRoad: file(relativePath: { eq: "icons/road.svg" }) {
        publicURL
      }

      iconKey: file(relativePath: { eq: "icons/carkey.svg" }) {
        publicURL
      }
    }
  `);

  const icons = {
    handout: data.iconHandout.publicURL,
    road: data.iconRoad.publicURL,
    keys: data.iconKey.publicURL,
  };

  const useStyles = makeStyles((theme: Theme) => ({
    usp: {
      display: 'block',
      textAlign: 'center',
      marginBottom: '2rem',

      [theme.breakpoints.up('lg')]: {
        marginBottom: '4rem',
      },

      '&:last-child': {
        marginBottom: '0',
      },

      '& svg': {
        width: '7rem',
        height: '7rem',
      },
    },
    title: {
      color: styleConfig.color.text.white,
      marginTop: '1rem',
      fontWeight: 800,
      fontSize: 20,
    },
    cta: {
      marginTop: '4rem',
      textAlign: 'center',
    },
  }));

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container>
        {list.map((usp, index) => (
          <Grid item xs={12} md={4} lg={12} key={index} className={classes.usp}>
            <img src={icons[usp.icon]} alt={usp.label} />
            <Typography className={classes.title} variant="body2">
              {usp.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

interface UspsProps {
  list: {
    icon: string;
    label: string;
  }[];
}

export default Usps;
